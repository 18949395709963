import React from "react";
import Hero from "../../Components/Home/Hero";
import Testimonial from "../../Components/Home/Testimonial";
import Stats from "../../Components/Home/Stats";
import OurCharge from "../../Components/Services/OurCharge";
import ServiceCard from "../../Components/Services/ServiceCard";
import AboutUs from "../../Components/About/AboutUs";
import Gallery from "../../Components/About/Gallery";
import ContactForm from "../../Components/Contact/ContactForm";
import { Helmet } from "react-helmet";

const Home = () => {
  navigator.geolocation.getCurrentPosition(function (position) {
    localStorage.setItem("DSN:lat", position.coords.latitude);
    localStorage.setItem("DSN:long", position.coords.longitude);
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="title"
          content="Hire Drivers in Kathmandu | Driver Service Nepal"
        />
        <meta
          name="description"
          content="Driver Service Nepal is the Best Driver Providing Agency in Kathmandu. We provide you driver at your doorstep for Hourly, part-time or full-time basis."
        />
        <title>Home | Driver Service Nepal</title>
      </Helmet>
      <Hero />
      <ServiceCard />
      <OurCharge />
      <AboutUs />
      <Testimonial />
      <Gallery />
      <Stats />
      <ContactForm />
    </>
  );
};

export default Home;
