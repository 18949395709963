import React, { useEffect, useState } from "react";
import { baseUrl } from "../../baseUrl";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import Select from "react-tailwindcss-select";
import { Helmet } from "react-helmet";

const Booking = () => {
  navigator.geolocation.getCurrentPosition(function (position) {
    localStorage.setItem("DSN:lat", position.coords.latitude);
    localStorage.setItem("DSN:long", position.coords.longitude);
  });

  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  var day = currentDate.getDate().toString().padStart(2, "0");
  var hours = currentDate.getHours().toString().padStart(2, "0");
  var minutes = currentDate.getMinutes().toString().padStart(2, "0");

  var formattedDateTime =
    year + "-" + month + "-" + day + "T" + hours + ":" + minutes;

  const token = localStorage.getItem("DSN:token");
  const long = JSON.parse(localStorage.getItem("DSN:long"));
  const lat = JSON.parse(localStorage.getItem("DSN:lat"));
  const [profile, setProfile] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pickUpLocation, setPickUpLocation] = useState("");
  const [dropOffLocation, setDropOffLocation] = useState("");
  const [pickUpTime, setPickUpTime] = useState("");
  const [timeForRide, setTimeForRide] = useState(null);
  const [rideShift, setRideShift] = useState(null);
  const [comment, setComment] = useState("N/A");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const role = localStorage.getItem("role");

  useEffect(() => {
    if (token) {
      axios
        .get(`${baseUrl}/auth/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfile(res.data.user);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });
    }
  }, [token]);

  const contactMethods = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>
      ),
      contact: "driverservicenepal@gmail.com",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
          />
        </svg>
      ),
      contact: "+977-9842441310",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>
      ),
      contact: "Budhanilkantha, Kathmandu",
    },
  ];

  //  react select option

  const timeForRideOptions = [
    { value: "4 Hours", label: "4 Hours" },
    { value: "8 Hours", label: "8 Hours" },
  ];

  const handleTimeForRideChange = (value) => {
    console.log("value:", value);
    setTimeForRide(value);
  };

  const rideShiftOptions = [
    { value: "Day", label: "Day" },
    { value: "Night", label: "Night" },
  ];

  const handleRideShiftChange = (value) => {
    console.log("value:", value);
    setRideShift(value);
  };
  //  react select option

  const handleSubmit = (e) => {
    e.preventDefault();
    if (timeForRide === null || rideShift === null) {
      toast.error("Please select the time and shift");
      return;
    }
    if (!token && phoneNumber.length < 10) {
      toast.error("Please add valid number.");
    } else {
      if (token) {
        setLoading(true);
        axios
          .post(
            `${baseUrl}/booking/book-ride`,
            {
              firstName: profile.firstName,
              lastName: profile.lastName,
              email: profile.email,
              phoneNumber: profile.phoneNumber,
              pickUpLocation,
              dropOffLocation,
              pickUpTime,
              comment,
              timeForRide: timeForRide.value,
              rideShift: rideShift.value,
              long,
              lat,
            },
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.success === true) {
              toast.success(res.data.message);
              navigate("/");
              // window.location.reload();
            } else {
              toast.error(res.data.message);
              navigate("/booking");
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        axios
          .post(
            `${baseUrl}/booking/book-ride`,
            {
              firstName,
              lastName,
              email,
              phoneNumber,
              pickUpLocation,
              dropOffLocation,
              pickUpTime,
              comment,
              timeForRide: timeForRide.value,
              rideShift: rideShift.value,
              long,
              lat,
            },
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.success === true) {
              toast.success(res.data.message);
              navigate("/");
            } else {
              toast.error(res.data.message);
              navigate("/booking");
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  return (
    <>
      {loading && <Spinner />}{" "}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="title"
          content="Book / Hire vehicle or Drivers in Kathmandu now | Driver Service Nepal"
        />
        <meta
          name="description"
          content="Book or Hire driver or vehicle for long tour in kathmandu, We provide driver hiring service and vehicle hiring service in Kathmandu."
        />
        <title>Booking | Driver Service Nepal</title>
      </Helmet>
      <main data-aos="fade-up" className="mt-28 mb-10">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="max-w-lg mx-auto gap-12 justify-between lg:flex lg:max-w-none">
            <div className="max-w-lg space-y-3">
              <h3 className="text-sky-600 font-semibold">Booking a ride?</h3>
              <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                Let us know how we can help in your ride.
              </p>
              <p>
                We’re here to help and make your ride easy and efficient, We
                look forward to hearing from you! Please fill out the form to
                book a ride, or us on the contact information bellow for
                queries.
              </p>
              <div>
                <ul className="mt-6 flex flex-wrap gap-x-10 gap-y-6 items-center">
                  {contactMethods.map((item, idx) => (
                    <li key={idx} className="flex items-center gap-x-3">
                      <div className="flex-none text-gray-400">{item.icon}</div>
                      <p>{item.contact}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex-1 mt-12 sm:max-w-lg lg:max-w-md">
              {/* form  */}
              <form className="space-y-5" onSubmit={handleSubmit}>
                {token && role === "User" ? (
                  <>
                    <p className=" text-red-500">
                      NOTE: You are booking this details as {profile.firstName}
                      &nbsp;
                      {profile.lastName}. If you want to change this details,
                      please logout.
                    </p>
                  </>
                ) : (
                  <>
                    <div className="mb-4 md:flex md:justify-between">
                      <div className="mb-4 md:mr-2 md:mb-0">
                        <input
                          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                          name="firstName"
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div className="md:ml-2">
                        <input
                          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                          name="lastName"
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="mb-4 md:flex md:justify-between">
                      <div className="mb-4 md:mr-2 md:mb-0">
                        <input
                          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                          name="email"
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="md:ml-2">
                        <input
                          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                          name="phoneNumber"
                          type="number"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="mb-4 md:flex md:justify-between">
                  <div className="mb-4 md:mr-2 md:mb-0">
                    <input
                      className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                      name="pickUpLocation"
                      type="text"
                      value={pickUpLocation}
                      onChange={(e) => setPickUpLocation(e.target.value)}
                      placeholder="Pick up Location"
                      required
                    />
                  </div>
                  <div className="md:ml-2">
                    <input
                      className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                      name="lastName"
                      type="text"
                      value={dropOffLocation}
                      onChange={(e) => setDropOffLocation(e.target.value)}
                      placeholder="Drop off Location"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label className="font-medium">Pickup Date & Time</label>
                  <input
                    min={formattedDateTime}
                    type="datetime-local"
                    required
                    name="pickUpTime"
                    value={pickUpTime}
                    onChange={(e) => setPickUpTime(e.target.value)}
                    className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                  />
                </div>
                <Select
                  placeholder="Time For Ride"
                  value={timeForRide}
                  onChange={handleTimeForRideChange}
                  options={timeForRideOptions}
                />{" "}
                <Select
                  placeholder="Ride Shift"
                  value={rideShift}
                  onChange={handleRideShiftChange}
                  options={rideShiftOptions}
                />
                <div>
                  <label className="font-medium">Comment</label>
                  <textarea
                    required
                    name="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-white font-medium bg-sky-600 hover:bg-sky-500 active:bg-sky-600 rounded-lg duration-150"
                >
                  Submit
                </button>
              </form>
              {/* form  */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Booking;
