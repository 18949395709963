import React from 'react';

function PhoneNumberButton(props) {
  function handleClick() {
    window.location.href = `tel:${props.phoneNumber}`;
  }

  return (
    <button onClick={handleClick}>
      {props.phoneNumber}
    </button>
  );
}

export default PhoneNumberButton;