import React from "react";
import OurCharge from "../../Components/Services/OurCharge";
import { Helmet } from "react-helmet";

const Price = () => {
  return (
    <div className="my-5">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="title" content="Hire Drivers and book vehicle at affordable and cheap price | Driver Service Nepal" />
        <meta
          name="description"
          content="Driver Service Nepal Provides Driver and vehicle hiring service at an cheap and affordable price in Kathmandu." />
        <title>Price | Driver Service Nepal</title>
      </Helmet>
      <OurCharge />
    </div>
  );
};

export default Price;
