import React from "react";
import Passenger from "../../Assets/passenger.webp";

const Stats = () => {
  const stats = [
    {
      data: "2K+",
      title: "Customers",
    },
    {
      data: "10K+",
      title: "Visits",
    },
    {
      data: "3+",
      title: "Countries",
    },
    {
      data: "30k+",
      title: "Total revenue",
    },
  ];
  return (
    <>
      {" "}
      <noscript>
        Hire, Kathmandu, valley, Driver, service, Ride Nepal, Driver Service
        Nepal, Driver Service, Driver Service in Kathmandu, Driver Service in
        Nepal, Driver Service in Kathmandu Valley, fulltime, part TimeRanges,
        airport pickup, affordable Price, cheap
      </noscript>
      <section data-aos="fade-up" className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 gap-x-12 items-start justify-between lg:flex md:px-8">
          <div className="sm:hidden lg:block lg:max-w-xl">
            <img
              src={Passenger}
              className="rounded-lg"
              alt="Driver Service Nepal Passenger "
            />
          </div>
          <div className="mt-6 gap-12 sm:mt-0 md:flex lg:block">
            <div className="max-w-2xl">
              <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                We do our best to make customers always happy
              </h3>
              <p className="mt-3 max-w-xl">
                Customer happiness is our top priority in the ride booking app.
                We strive to provide a seamless and enjoyable experience,
                ensuring that every user feels valued and satisfied with our
                service.
              </p>
            </div>
            <div className="flex-none mt-6 md:mt-0 lg:mt-6">
              <ul className="inline-grid gap-y-8 gap-x-14 grid-cols-2">
                {stats.map((item, idx) => (
                  <li key={idx} className="">
                    <h4 className="text-4xl text-sky-600 font-semibold">
                      {item.data}
                    </h4>
                    <p className="mt-3 font-medium">{item.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stats;
