import React from "react";
import DriverDayCharge from "../../Assets/driver-day-charge.webp";
import DriverNightCharge from "../../Assets/driver-night-charge.webp";
import DriverDayAirport from "../../Assets/driver-day-airport.webp";
import DriverNightAirport from "../../Assets/driver-night-airport.webp";

const OurCharge = () => {
  const team = [
    {
      avatar: DriverDayCharge,
      name: "Day Charge (6 am to 6 pm)",
      title: "4hrs Rs 1200/- & additional Rs 300/-",
    },
    {
      avatar: DriverDayAirport,
      name: "Airport Drop (Day)",
      title: "Rs 1000/-",
    },
    {
      avatar: DriverNightCharge,
      name: "Night Charge (6 pm to 6 am)",
      title: "4 hrs Rs 1500/- additional Rs 500/-",
    },
    {
      avatar: DriverNightAirport,
      name: "Airport Drop (Night)",
      title: "Rs 1200/-",
    },
  ];

  return (
    <>
      {" "}
      <noscript>
        Hire, Kathmandu, valley, Driver, service, Ride Nepal, Driver Service
        Nepal, Driver Service, Driver Service in Kathmandu, Driver Service in
        Nepal, Driver Service in Kathmandu Valley, fulltime, part TimeRanges,
        airport pickup, affordable Price, cheap
      </noscript>
      <section data-aos="fade-up" className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <div className="max-w-xl mx-auto sm:text-center">
            <h3 className="text-sky-800 text-3xl font-semibold sm:text-4xl">
              Our Affordable Prices
            </h3>
            <p className="text-gray-600 mt-3">
              Here are the cheap and affordable riding prices Driver Service Nepal services.
            </p>
          </div>
          <div className="mt-12">
            <ul className="grid gap-8 sm:grid-cols-2 md:grid-cols-2">
              {team.map((item, idx) => (
                <li key={idx}>
                  <div className="w-full h-60 sm:h-52 md:h-56">
                    <img
                      src={item.avatar}
                      className="w-full h-full object-cover object-center shadow-md rounded-xl"
                      alt={`Driver Service Nepal ${item.name}`}
                    />
                  </div>
                  <div className="mt-4">
                    <h4 className="text-lg text-gray-700 font-semibold">
                      {item.name}
                    </h4>
                    <p className="text-sky-600">{item.title}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurCharge;
