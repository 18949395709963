import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import { Helmet } from "react-helmet";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !email ||
      !password ||
      firstName === "" ||
      lastName === "" ||
      phoneNumber === "" ||
      email === "" ||
      password === ""
    ) {
      toast.warning("Please fill all the fields!");
    } else {
      setLoading(true);
      await axios
        .post(
          `${baseUrl}/auth/register`,
          {
            firstName,
            lastName,
            phoneNumber,
            email,
            password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            toast.success(res.data.message);
            navigate("/login");
          } else {
            toast.warning(res.data.message);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong!");
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    let isAuth = localStorage.getItem("DSN:token");
    if (isAuth && isAuth !== null) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      {loading && <Spinner />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign Up | Driver Service Nepal</title>
      </Helmet>
      <div
        data-aos="fade-up"
        className="w-full mt-28 bg-gray-50 flex flex-col sm:justify-center items-center pt-6 sm:pt-0"
      >
        <div className="w-full sm:max-w-md p-5 mx-auto">
          <h2 className="mb-12 text-center text-5xl font-extrabold">
            Register.
          </h2>
          <form
            className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
            onSubmit={handleRegister}
          >
            <div className="mb-4 md:flex md:justify-between">
              <div className="mb-4 md:mr-2 md:mb-0">
                <label className="block mb-1" htmlFor="firstName">
                  First Name
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="md:ml-2">
                <label className="block mb-1" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>
            <div className="mb-4 md:flex md:justify-between">
              <div className="mb-4 md:mr-2 md:mb-0">
                <label className="block mb-1" htmlFor="email">
                  Email
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="md:ml-2">
                <label className="block mb-1" htmlFor="phoneNumber">
                  Phone Number
                </label>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="number"
                  placeholder="Phone Number"
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-1" htmlFor="password">
                Password
              </label>
              <input
                className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </div>
            <div className="mb-6 text-center">
              <button
                className="w-full px-4 py-2  text-white bg-sky-600 rounded-full hover:bg-sky-700 focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Register Account
              </button>
            </div>
            <div className="text-center">
              <Link
                className="inline-block text-sm align-baseline underline"
                to="/login"
              >
                Already have an account? Login!
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
