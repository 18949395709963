import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import moment from "moment";
import EmptyData from "../../Components/EmptyData/EmptyData";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const token = localStorage.getItem("DSN:token");
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/auth/all-feedbacks?limit=${10}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFeedbacks(res.data.feedbacks);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, page]);

  const handleDelete = (id) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/auth/delete-feedback`,
        {
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          setFeedbacks(feedbacks.filter((item) => item._id !== id));
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Spinner />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Feedback | Driver Service Nepal</title>
      </Helmet>
      <div
        data-aos="fade-up"
        className="max-w-screen-xl mt-28 mx-auto px-4 md:px-8 mb-10"
      >
        <div className="items-start justify-between md:flex">
          <div className="max-w-lg">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              Admin Dashboard
            </h3>
            <p className="text-gray-600 mt-2">All Feedbacks</p>
          </div>
          <div className="mt-3 md:mt-0">
            <Link
              to="/admin/all-users"
              className="inline-block mr-2 px-4 py-2 text-white duration-150 font-medium bg-sky-600 rounded-lg hover:bg-sky-500 active:bg-sky-700 md:text-sm"
            >
              Users List
            </Link>
            <Link
              to="/admin/dashboard"
              className="inline-block px-4 py-2 text-white duration-150 font-medium bg-sky-600 rounded-lg hover:bg-sky-500 active:bg-sky-700 md:text-sm"
            >
              Booking List
            </Link>
          </div>
        </div>
        {feedbacks.length === 0 ? (
          <EmptyData />
        ) : (
          <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
            <table className="w-full table-auto text-sm text-left">
              <thead className="bg-gray-50 text-gray-600 font-medium border-b">
                <tr>
                  <th className="py-3 px-6">Full Name</th>
                  <th className="py-3 px-6">Email</th>
                  <th className="py-3 px-6">Phone Number</th>
                  <th className="py-3 px-6">Feedback</th>
                  <th className="py-3 px-6">Date</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 divide-y">
                {feedbacks.map((item, idx) => (
                  <tr key={idx}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.fullName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.phoneNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.feedback}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {moment(item.givenAt).format("MMMM Do YYYY, h:mm:ss a")}
                    </td>
                    <td className="text-right px-6 whitespace-nowrap">
                      <button
                        onClick={() => handleDelete(item._id)}
                        className="py-2 px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg"
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {feedbacks.length !== 0 && (
        <div className="max-w-screen-xl mx-auto mt-12 px-4 text-gray-600 md:px-8 m-5 mb-5">
          <div className="flex items-center justify-between text-sm text-gray-600 font-medium">
            <button
              disabled={page === 1}
              className="px-4 py-2 border rounded-lg duration-150 text-white bg-sky-600 hover:bg-sky-500"
              onClick={() => {
                setPage(page - 1);
              }}
            >
              Previous
            </button>

            <button
              disabled={feedbacks.length < 10}
              className="px-4 py-2 border rounded-lg duration-150 text-white bg-sky-600 hover:bg-sky-500"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
