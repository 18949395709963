import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./Screens/Home/Home";
import TopHeader from "./Components/TopHeader/TopHeader";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import PageNotFound from "./Screens/PageNotFound/PageNotFound";
import Login from "./Screens/Login/Login";
import Register from "./Screens/Register/Register";
import Contact from "./Screens/Contact/Contact";
import About from "./Screens/About/About";
import Booking from "./Screens/Booking/Booking";
import Services from "./Screens/Services/Services";
import Profile from "./Screens/User/Profile";
import Dashboard from "./Screens/Admin/Dashboard";
import OwnBookingList from "./Screens/User/OwnBookingList";
import SingleBookingUser from "./Screens/User/SingleBookingUser";
import SingleBooking from "./Screens/Admin/SingleBooking";
import UserList from "./Screens/Admin/UserList";
import Feedbacks from "./Screens/Admin/Feedbacks";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import TermsAndConditions from "./Screens/TermsAndConditions/TermsAndConditions";
import Price from "./Screens/Price/Price";

const App = () => {
  const role = localStorage.getItem("role");
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <TopHeader />
      <Navbar />
      <Routes>
        {" "}
        <Route element={<ProtectedRoute role={role} />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/all-users" element={<UserList />} />
          <Route path="/admin/feedbacks" element={<Feedbacks />} />
          <Route
            path="/admin/single-booking-admin/:id"
            element={<SingleBooking />}
          />
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/terms-condition" element={<TermsAndConditions />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/price" element={<Price />} />
        <Route path="/own-booking" element={<OwnBookingList />} />
        <Route
          path="/single-booking-user/:id"
          element={<SingleBookingUser />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
};

export default App;
