import React from "react";
import Gallery from "../../Components/About/Gallery";
import AboutUs from "../../Components/About/AboutUs";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        
        <meta name="title" content="Driver Hiring Service kathmandu  | About Driver Service Nepal" />
        <meta
          name="description"
          content="Driver Service Nepal is trust worthy and follows all the saftey protocol to ensure Safe Travells of our Customers. Rest asured, Our Skilled Drivers got your back"/>
        <title>About | Driver Service Nepal</title>
      </Helmet>
      <AboutUs />
      <Gallery />
    </>
  );
};

export default About;
