import React from "react";

const EmptyData = () => {
  return (
    <>
      <div className="h-screen  flex flex-col gap-4 items-center justify-center bg-white">
        <div className="w-auto border-2 border-b-4 border-gray-700 rounded-xl hover:text-white hover:bg-gray-500">
          <p className="w-fit px-4 py-1 text-sm font-bold  rounded-tl-lg rounded-br-xl">
            No data found!
          </p>
        </div>
      </div>
    </>
  );
};

export default EmptyData;
