import React from "react";
import { Container } from "@mui/material";

const Map = () => {
  return (
    <>
      <Container>
        <div data-aos="fade-left" className="   mt-1 mb-3">
          <p className="block   text-sky-600 pb-5 underline text-center  text-3xl text-primary capitalize font-semibold">
            Find Us on Google Map
          </p>
          <div className="   flex   justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99756.41014017245!2d85.23852663633106!3d27.67204332225255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x261f944f450e1729%3A0x5aa1414eea00d22e!2sDriver%20Service%20Nepal!5e0!3m2!1sen!2snp!4v1688894613496!5m2!1sen!2snp"
              allowFullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="stem-cbs map"
              className="w-full h-[80vh] px-3"
            ></iframe>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Map;
