import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import moment from "moment";
import EmptyData from "../../Components/EmptyData/EmptyData";
import { Helmet } from "react-helmet";

const OwnBookingList = () => {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const token = localStorage.getItem("DSN:token");
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/booking/my-bookings?limit=${10}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBookings(res.data.bookings);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, page]);

  return (
    <>
      {loading && <Spinner />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Booking List | Driver Service Nepal</title>
      </Helmet>
      <div
        data-aos="fade-up"
        className="max-w-screen-xl mt-28 mx-auto px-4 md:px-8 mb-10"
      >
        <div className="items-start justify-between md:flex">
          <div className="max-w-lg">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              User Booking List
            </h3>
            <p className="text-gray-600 mt-2">All Own Booking List</p>
          </div>
        </div>
        {bookings.length === 0 ? (
          <EmptyData />
        ) : (
          <div className="mt-12 shadow-sm border rounded-lg overflow-x-auto">
            <table className="w-full table-auto text-sm text-left">
              <thead className="bg-gray-50 text-gray-600 font-medium border-b">
                <tr>
                  <th className="py-3 px-6">First Name</th>
                  <th className="py-3 px-6">Last Name</th>
                  <th className="py-3 px-6">Email</th>
                  <th className="py-3 px-6">Phone Number</th>
                  <th className="py-3 px-6">Date</th>
                  <th className="py-3 px-6"></th>
                </tr>
              </thead>
              <tbody className="text-gray-600 divide-y">
                {bookings.map((item, idx) => (
                  <tr key={idx}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.firstName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.lastName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.phoneNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {moment(item.date).format("MMMM Do YYYY, h:mm:ss a")}
                    </td>
                    <td className="text-right px-6 whitespace-nowrap">
                      <Link
                        to={`/single-booking-user/${item._id}`}
                        className="py-2 px-3 font-medium text-sky-600 hover:text-sky-500 duration-150 hover:bg-gray-50 rounded-lg"
                      >
                        <i className="fa-solid fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {bookings.length !== 0 && (
        <div className="max-w-screen-xl mx-auto mt-12 px-4 text-gray-600 md:px-8 m-5 mb-5">
          <div className="flex items-center justify-between text-sm text-gray-600 font-medium">
            <button
              disabled={page === 1}
              className="px-4 py-2 border rounded-lg duration-150 text-white bg-sky-600 hover:bg-sky-500"
              onClick={() => {
                setPage(page - 1);
              }}
            >
              Previous
            </button>

            <button
              disabled={bookings.length < 10}
              className="px-4 py-2 border rounded-lg duration-150 text-white bg-sky-600 hover:bg-sky-500"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default OwnBookingList;
