import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Assets/logo.webp";
import { toast } from "react-toastify";

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isSticky = scrollY > 0;

  const handleScrollTo = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("DSN:token");
  const role = localStorage.getItem("role");

  const handleLogout = () => {
    localStorage.removeItem("DSN:token");
    localStorage.removeItem("DSN:lat");
    localStorage.removeItem("DSN:long");
    localStorage.removeItem("role");
    toast.success("Logout Successfully.");
    navigate("/login");
  };

  return (
    <>
      <nav
        className={`flex items-center justify-between flex-wrap bg-gray-100 px-4 py-2 fixed w-full transition-all duration-300 ease-in-out z-10 ${
          isSticky ? "shadow-lg -mt-14" : ""
        }`}
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div
            className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
            onClick={(e) => handleScrollTo(e, "home")}
          >
            <Link
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 mt-2 whitespace-nowrap"
              to="/"
            >
              <img
                src={Logo}
                alt="Driver Service Nepal Logo"
                width="155px"
                height="155px"
                draggable="false"
              />
            </Link>
            <button
              className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen === false ? (
                <i className="fas fa-bars"></i>
              ) : (
                <i className="fa-solid fa-xmark"></i>
              )}
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto ">
              <li
                className="nav-item"
                onClick={(e) => handleScrollTo(e, "home")}
              >
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75 hover:text-sky-900"
                  to="/"
                >
                  <span className="ml-2">home</span>
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={(e) => handleScrollTo(e, "services")}
              >
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75  hover:text-sky-900"
                  to="/services"
                >
                  <span className="ml-2">Services</span>
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={(e) => handleScrollTo(e, "price")}
              >
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75  hover:text-sky-900"
                  to="/price"
                >
                  <span className="ml-2">Price</span>
                </Link>
              </li>
              {(token && role === "User") || !token ? (
                <li
                  className="nav-item"
                  onClick={(e) => handleScrollTo(e, "booking")}
                >
                  <Link
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75  hover:text-sky-900"
                    to="/booking"
                  >
                    <span className="ml-2">Booking</span>
                  </Link>
                </li>
              ) : (
                ""
              )}{" "}
              <li
                className="nav-item"
                onClick={(e) => handleScrollTo(e, "about")}
              >
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75  hover:text-sky-900"
                  to="/about-us"
                >
                  <span className="ml-2">About Us</span>
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={(e) => handleScrollTo(e, "contact")}
              >
                <Link
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75  hover:text-sky-900"
                  to="/contact-us"
                >
                  <span className="ml-2">Contact us</span>
                </Link>
              </li>
              {token && role === "User" && (
                <>
                  <li
                    className="nav-item"
                    onClick={(e) => handleScrollTo(e, "own-booking")}
                  >
                    <Link
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75  hover:text-sky-900"
                      to="/own-booking"
                    >
                      <span className="ml-2">My Bookings</span>
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    onClick={(e) => handleScrollTo(e, "profile")}
                  >
                    <Link
                      to="/profile"
                      className="px-3 py-2 mr-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 bg-sky-600 rounded-lg"
                    >
                      <span className="ml-2 pr-1">
                        <i className="fa-solid fa-user"></i>
                      </span>
                    </Link>
                  </li>
                </>
              )}
              {token && role === "Admin" && (
                <li
                  className="nav-item mr-3"
                  onClick={(e) => handleScrollTo(e, "dashboard")}
                >
                  <Link
                    to="/admin/dashboard"
                    className="px-3 py-2 ml-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 bg-sky-600 rounded-lg"
                  >
                    <span className="ml-2 pr-1">Dashboard</span>
                  </Link>
                </li>
              )}
              {/* for user operation */}
              {token ? (
                <>
                  <li
                    className="nav-item"
                    onClick={(e) => handleScrollTo(e, "logout")}
                  >
                    <button
                      onClick={() => {
                        handleLogout();
                      }}
                      className="px-3 py-2 mr-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 bg-red-600 rounded-lg"
                    >
                      <span className="ml-2 pr-1">
                        <i className="fa-solid fa-right-from-bracket"></i>
                      </span>
                    </button>
                  </li>
                </>
              ) : (
                <li
                  className="nav-item"
                  onClick={(e) => handleScrollTo(e, "login")}
                >
                  <Link
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 bg-sky-600 rounded-lg"
                    to="/login"
                  >
                    <span className="ml-2 pr-1">Join Now</span>
                  </Link>
                </li>
              )}
              {/* for user operation */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
