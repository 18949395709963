import React from 'react';

function EmailButton(props) {
  function handleClick() {
    window.location.href = `mailto:${props.email}`;
  }

  return (
    <button onClick={handleClick}>
      {props.email}
    </button>
  );
}

export default EmailButton;