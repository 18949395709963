import React from "react";

const ServiceCard = () => {
  const features = [
    {
      title: "Hourly One Time Driver",
      desc: "Driver Service Nepal enables users to conveniently hire professional drivers on an hourly basis, ensuring flexible and cheap and affordable price ,and personalized transportation solutions tailored to their specific requirements.",
    },
    {
      title: "Part Time Driver",
      desc: "Driver Service Nepal offers users the option to hire skilled drivers for specific time slots, providing flexible and cost-effective and affordable transportation solutions based on their schedule and needs.",
    },
    {
      title: "Full-Time Driver",
      desc: " Driver Service Nepal allows users to hire dedicated drivers for extended periods, ensuring uninterrupted and reliable transportation services for daily commuting or longer durations.",
    },
    {
      title: "Airport Pick & Drop",
      desc: " Driver Service Nepal provides a seamless and convenient solution for users to book reliable drivers who specialize in timely and hassle-free transportation to and from the airport, ensuring a smooth travel experience.",
    },
    {
      title: "Night Shift Driver",
      desc: "Driver Service Nepal offers users the convenience and safety of booking professional drivers specifically for nighttime journeys, providing reliable transportation during late hours for a secure and comfortable ride.",
    },
    {
      title: "Long Tour Driver",
      desc: "Driver Service Nepal provides experienced drivers for extended trips, allowing users to enjoy comfortable and reliable transportation services for their explorations and sightseeing adventures across Nepal.",
    },
    {
      title: "Vehicle With Driver",
      desc: "Driver Service Nepal offers users the convenience of booking a private car along with a professional driver, ensuring a comfortable and personalized transportation experience tailored to their specific needs and preferences.",
    },
  ];
  return (
    <>
      <noscript>
        Hire, Kathmandu, valley, Driver, service, Ride Nepal, Driver Service
        Nepal, Driver Service, Driver Service in Kathmandu, Driver Service in
        Nepal, Driver Service in Kathmandu Valley, fulltime, part TimeRanges,
        airport pickup, affordable Price, cheap
      </noscript>
      <section data-aos="fade-up" className="mt-28 mb-10">
        <div className="max-w-screen-xl mx-auto px-4 text-center text-gray-600 md:px-8">
          <div className="max-w-2xl mx-auto">
            <h3 className="text-sky-800 text-3xl font-semibold sm:text-4xl">
              Our Services
            </h3>
            <p className="mt-3">
              Our Driver Service Nepal offers a convenient and reliable way to
              book rides and drivers in Kathmandu, ensuring quick and
              comfortable, affordable transportation at your fingertips.
            </p>
          </div>
          <div className="mt-12">
            <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((item, idx) => (
                <li key={idx} className="space-y-3">
                  <h4 className="text-lg text-gray-800 font-semibold">
                    {idx + 1}. {item.title}
                  </h4>
                  <p>{item.desc}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceCard;
