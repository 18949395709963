import React from "react";
import ServiceCard from "../../Components/Services/ServiceCard";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="title" content="Hire Drivers and vehicle in Kathmandu | Driver Service Nepal" />
        <meta
          name="description"
          content="Driver Service Nepal provides various services like driver hiring, vehicle booking with driver or with out driver in Kathmandu. We provide you driver at your doorstep for Hourly, part-time or full-time basis." />
        <title>Services | Driver Service Nepal</title>
      </Helmet>
      <ServiceCard />
    </>
  );
};

export default Services;
