import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

const SingleBooking = () => {
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState({});
  const token = localStorage.getItem("DSN:token");

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/booking/single-booking-admin/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBooking(res.data.booking);
        setLat(res.data.booking.lat);
        setLong(res.data.booking.long);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, token, lat, long]);

  return (
    <>
      {loading && <Spinner />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Booking Details | Driver Service Nepal</title>
      </Helmet>
      <Container className="mt-28 mb-10">
        <p className="text-center text-xl text-sky-700 py-2">
          Single User Details
        </p>
        <div className=" grid grid-cols-2 grid-flow-row   gap-4">
          <p className="flex gap-1 ">
            <span className="font-bold">Name:</span>
            <span>
              {booking.firstName} {booking.lastName}
            </span>
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold">Email:</span>
            <span>{booking.email}</span>
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Phone Number:</span>
            {booking.phoneNumber}
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Pickup Location:</span>
            {booking.pickUpLocation}
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Drop off Location:</span>
            {booking.dropOffLocation}
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Pickup Date & Time:</span>
            {moment(booking.pickUpTime).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Time for Ride:</span>
            {booking.timeForRide}
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Ride Shift:</span>
            {booking.rideShift}
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Longitude:</span>
            {booking.long}
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Lattitude:</span>
            {booking.lat}
          </p>
          <p className="flex gap-1 ">
            <span className="font-bold"> Booking Date:</span>
            {moment(booking.bookedAt).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </div>
        <p className=" ">
          <p className="mt-3 font-bold">Comment:</p>
          <p className="mt-3 text-gray-700 text-sm">{booking.comment}</p>
        </p>
      </Container>
      <div data-aos="fade-left" className="   mt-1 mb-3">
        <p className="block   text-sky-600 pb-5 underline text-center  text-3xl text-primary capitalize font-semibold">
          Location of {booking.firstName}&nbsp;{booking.lastName}
        </p>
        <div className="   flex   justify-center">
          {booking && lat && long && (
            <iframe
              allowFullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="User Location"
              className="w-full h-[80vh] px-3"
              src={`https://maps.google.com/maps?q=${lat},${long}&output=embed`}
            ></iframe>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleBooking;
