import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import { Helmet } from "react-helmet";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password || email === "" || password === "") {
      toast.warning("Please fill all the fields!");
    } else {
      setLoading(true);
      await axios
        .post(
          `${baseUrl}/auth/login`,
          {
            email,
            password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            toast.success(res.data.message);
            localStorage.setItem("DSN:token", res.data.token);

            // Split the message into an array of words
            const words = res.data.message.split(" ");
            // Grab the first word
            const role = words[0];

            localStorage.setItem("role", role);
            navigate("/");
          } else {
            toast.warning(res.data.message);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong!");
          console.log(err);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    let isAuth = localStorage.getItem("DSN:token");
    if (isAuth && isAuth !== null) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      {loading && <Spinner />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | Driver Service Nepal</title>
      </Helmet>
      <div
        data-aos="fade-up"
        className="w-full min-h-screen bg-gray-50 flex flex-col sm:justify-center items-center pt-6 sm:pt-0"
      >
        <div className="w-full sm:max-w-md p-5 mx-auto">
          <h2 className="mb-12 text-center text-5xl font-extrabold">Login.</h2>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block mb-1" htmlFor="email">
                Email-Address
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email address"
                className="py-2 px-3 border border-gray-300 focus:border-sky-300 focus:outline-none focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your password"
                className="py-2 px-3 border border-gray-300 focus:border-sky-300 focus:outline-none focus:ring focus:ring-sky-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
              />
            </div>
            <div className="mt-6 flex items-center justify-between">
              <Link to="/forgot-password" className="text-sm">
                Forgot your password?
              </Link>
            </div>
            <div className="mt-6">
              <button className="w-full inline-flex items-center justify-center px-4 py-2 bg-sky-600 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-sky-700 active:bg-sky-700 focus:outline-none focus:border-sky-700 focus:ring focus:ring-sky-200 disabled:opacity-25 transition">
                Sign In
              </button>
            </div>
            <div className="mt-6 text-center">
              <Link to="/register" className="underline">
                Sign up for an account
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
