import React from "react";
import { Link } from "react-router-dom";
import Driver from "../../Assets/driver.webp";

const Hero = () => {
  const token = localStorage.getItem("DSN:token");
  return (
    <>
      <section data-aos="fade-up" className="py-24">
        <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
          <div className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl">
            <h1 className="text-sm text-sky-600 font-medium">
              Over 2K successful rides
            </h1>
            <h2 className="text-4xl text-gray-800 font-extrabold md:text-5xl">
              Driver Service Nepal
            </h2>
            <p>
              Driver Service Nepal, based in Kathmandu Valley, is a game-changer
              in the transportation industry. With its innovative platform,
              passengers can effortlessly request and schedule rides, making it
              the go-to choice for countless travelers. The user-friendly
              interface of Driver Service Nepal ensures a smooth and convenient
              experience for all users, whether they need a driver for a
              full-time commitment or part-time assistance. The service caters
              to a wide range of time requirements, accommodating the diverse
              needs of passengers in Kathmandu and throughout Nepal.
              Additionally, the service offers airport pickup, ensuring a
              seamless transition for travelers arriving in the country. What
              sets Driver Service Nepal apart is its commitment to
              affordability, providing competitive and cheap prices without
              compromising on the quality of service. By revolutionizing the way
              people travel, Driver Service Nepal has become synonymous with
              reliable, convenient, and affordable transportation in Kathmandu
              Valley and beyond.
              <noscript>
                Hire, Kathmandu, valley, Driver, service, Ride Nepal, Driver
                Service Nepal, Driver Service, Driver Service in Kathmandu,
                Driver Service in Nepal, Driver Service in Kathmandu Valley,
                fulltime, part TimeRanges, airport pickup, affordable Price,
                cheap
              </noscript>
            </p>
            <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
              <Link
                to="/booking"
                className="block py-2 px-4 text-center text-white font-medium bg-sky-600 duration-150 hover:bg-sky-500 active:bg-sky-700 rounded-lg shadow-lg hover:shadow-none"
              >
                Book a ride
              </Link>
              {!token && (
                <Link
                  to="/login"
                  className="flex items-center justify-center gap-x-2 py-2 px-4 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-lg md:inline-flex"
                >
                  Join Now
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
              )}
            </div>
          </div>
          <div className="flex-none mt-14 md:mt-0 md:max-w-xl">
            <img
              src={Driver}
              className=" md:rounded-tl-[108px]"
              alt="Driver Service Nepal Driver Hero Section"
            />
          </div>
        </div>
        <div className="bg-sky-600 mt-3">
          <div className="max-w-screen-xl mx-auto px-4 py-3 items-center gap-x-4 justify-center text-white sm:flex md:px-8">
            <p className="py-2 font-medium">
              We will transport you safely to your destination.
            </p>
            <Link
              to="/booking"
              className="flex-none inline-block w-full mt-3 py-2 px-3 text-center text-sky-600 font-medium bg-white duration-150 hover:bg-gray-100 active:bg-gray-200 rounded-lg sm:w-auto sm:mt-0 sm:text-sm"
            >
              Schedule Ride Now
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
