import React from "react";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  const terms = [
    {
      id: 1,
      title:
        "Please demand a bill from our drivers, review it thoroughly, sign it, and then only pay.",
    },
    {
      id: 2,
      title:
        "Please refrain from urging the driver to go above the speed limit, run red lights, drive in the wrong lane, overtake, compete, etc.",
    },
    {
      id: 3,
      title:
        "We strongly advise against leaving any money, valuables, or priceless items—such as laptops, mobile phones, wallets, handbags, baggage, etc.—in the car or in the drivers' custody.",
    },
    {
      id: 4,
      title:
        "Any loss, theft, or damage will not be the responsibility of Hello Driver or any driver hired via it.",
    },
    {
      id: 5,
      title:
        "Every time the driver reports, kindly demand that they show you their corporate ID card.",
    },
    {
      id: 6,
      title:
        "Because Hello Driver or its driver wouldn't be a party to, be found guilty of, or be a witness in any legal proceedings held by any court of law due to such incidents, loss, or thefts, you should ONLY use Hello Driver services if your vehicle is covered under a comprehensive third-party insurance policy through a reputable motor vehicle insurer in Nepal.",
    },
    {
      id: 7,
      title:
        "At the conclusion of the booking, the customer should pay the driver directly in cash, which we ONLY accept.",
    },
    {
      id: 8,
      title:
        "All parking fees, tolls, and any supplemental costs are the responsibility of the consumer.",
    },
    {
      id: 9,
      title:
        "Customers should inspect their cars for any damages. After the driver has left the premises, complaints won't be taken into consideration.",
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and Conditions | Driver Service Nepal</title>
      </Helmet>
      <section data-aos="fade-up" className="mt-28 mb-10">
        <div className="max-w-screen-xl mx-auto px-4  text-gray-600 md:px-8">
          <div className="max-w-2xl mx-auto">
            <h3 className="text-gray-800 text-3xl text-center font-semibold sm:text-4xl">
              Our Terms and Conditions
            </h3>
          </div>
          <div className="mt-12">
            <div className="relative block p-8 overflow-hidden border bg-white border-slate-100 rounded-lg ml-6 mr-6">
              <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-purple-300 via-sky-500 to-indigo-600"></span>
              {terms.map((term) => (
                <div key={term.id} className="justify-start sm:flex mt-4">
                  <div>
                    <h5 className="text-slate-900">
                      {term.id}. {term.title}
                    </h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
