import React from "react";
import EmailButton from "../EmailButton/EmailButton";
import PhoneNumberButton from "../PhoneNumber/PhoneNumber";
import "./TopHeader.css";
import WhatsAppButton from "../WhatsAppButton/WhatsAppButton";
const TopHeader = () => {
  return (
    <>
      <div className="top-header bg-sky-600 w-full p-2 text-white flex  flex-shrink flex-wrap justify-around">
        <div className="p-1">
          <i className="fa-solid fa-building p-1"></i>Driver Service Nepal
        </div>
        <div className="p-1">
          <i className="fa-solid fa-phone p-1"></i>
          <PhoneNumberButton phoneNumber="+977-9842441310" />
        </div>
        <div className="p-1">
          <i class="fa-brands fa-whatsapp p-1"></i>
          <WhatsAppButton number="+977-9842441310" />
        </div>
        <div className="p-1">
          <i className="fa-solid fa-envelope p-1"></i>
          <EmailButton email="driverservicenepal@gmail.com" />
        </div>
        <div className="p-1">
          <i className="fa-solid fa-location-dot p-1"></i>Budhanilkantha,
          Kathmandu
        </div>
      </div>

      <div className="top-header-sm bg-sky-600 w-full p-2 text-white flex  flex-shrink flex-wrap justify-around">
        <div className="p-1">
          <i className="fa-solid fa-building p-1"></i>Driver service Nepal
        </div>
        <div className="p-1">
          <i className="fa-solid fa-phone p-1"></i>
          <PhoneNumberButton phoneNumber="+977-9842441310" />
        </div>
      </div>
    </>
  );
};

export default TopHeader;
