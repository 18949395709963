import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo.webp";
import moment from "moment";

const Footer = () => {
  return (
    <>
      <div data-aos="fade-up" className="mx-auto">
        <footer className="p-4 bg-gray-900 sm:p-6 ">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <Link to="/" className="flex items-center">
                <img
                  src={Logo}
                  className="mr-4 h-10"
                  alt="Driver Service Nepal Logo"
                />
                <span className="self-center text-gray-100 text-xl font-semibold whitespace-nowrap ">
                  Driver Service Nepal
                </span>
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h3 className="mb-6 text-sm font-semibold text-gray-100 uppercase">
                  Useful Resources
                </h3>
                <ul>
                  <li>
                    <Link
                      to="/booking"
                      className="text-gray-100 hover:underline"
                    >
                      Booking
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      className="text-gray-100 hover:underline"
                    >
                      Contact-us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about-us"
                      className="text-gray-100 hover:underline"
                    >
                      About-us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services"
                      className="text-gray-100 hover:underline"
                    >
                      Services
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="mb-6 text-sm font-semibold text-gray-100 uppercase ">
                  Terms and Conditions
                </h3>
                <ul>
                  <li className="mb-4">
                    <Link
                      to="/terms-condition"
                      className="text-gray-100 hover:underline "
                    >
                      Privacy Policy and Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-100 sm:text-center ">
              © {moment(new Date()).format("YYYY")}
              <Link to="/" className="hover:underline pl-1">
                Driver Service Nepal
              </Link>
              . All Rights Reserved.
            </span>
            <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
              <Link to="#" className="text-gray-100 hover:text-gray-300 ">
                <i className="fa-brands fa-facebook w-5 h-5"></i>
              </Link>
              <Link to="#" className="text-gray-100 hover:text-gray-300 ">
                <i className="fa-brands fa-instagram w-5 h-5"></i>
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
