import React from "react";

function WhatsAppButton(props) {
  console.log(props);
  function handleClick() {
    window.location.href = `whatsapp:${props.number}`;
  }

  return <button onClick={handleClick}>{props.number}</button>;
}

export default WhatsAppButton;
