import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Profile = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("DSN:token");
  const [updateFirstName, setUpdateFirstName] = useState();
  const [updateLastName, setUpdateLastName] = useState();
  const [updateEmail, setUpdateEmail] = useState();
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/auth/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data.user);
        setUpdateFirstName(res.data.user.firstName);
        setUpdateLastName(res.data.user.lastName);
        setUpdateEmail(res.data.user.email);
        setUpdatePhoneNumber(res.data.user.phoneNumber);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${baseUrl}/auth/update-profile`,
        {
          firstName: updateFirstName,
          lastName: updateLastName,
          email: updateEmail,
          phoneNumber: updatePhoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          navigate("/profile");
          window.location.reload();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {loading && <Spinner />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Profile | Driver Service Nepal</title>
      </Helmet>
      <div data-aos="fade-up" className=" mt-60 mb-20">
        <div className="max-w-lg mx-auto my-10 bg-white rounded-lg shadow-2xl p-5">
          <h2 className="text-center text-2xl font-semibold mt-3">
            {user.firstName} {user.lastName}
          </h2>
          <p className="text-center text-gray-600 mt-1">{user.email}</p>
          <p className="text-center text-gray-600 mt-1">{user.phoneNumber}</p>
          <p className="text-center text-gray-600 mt-1">
            {moment(user.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </div>
      </div>

      <form
        data-aos="fade-up"
        onSubmit={handleUpdate}
        className="mb-5 justify-center items-center w-full shadow rounded-lg bg-white px-6 flex flex-col md:w-1/2 lg:w-1/3 m-auto"
      >
        <h2 className="text-2xl my-4">Update your Profile</h2>
        <div className="w-full p-2 justify-start flex flex-col">
          <div className="m-2 flex flex-row">
            <input
              className="border border-gray-200 rounded-r-lg outline-none focus:ring-1 ring-sky-400 w-full pl-1"
              name="updateFirstName"
              type="text"
              value={updateFirstName}
              onChange={(e) => setUpdateFirstName(e.target.value)}
              required
              placeholder="First Name"
            />
          </div>
          <div className=" flex m-2 flex-row">
            <input
              className="border border-gray-200 rounded-r-lg outline-none focus:ring-1 ring-sky-400 w-full pl-1"
              name="updateLastName"
              type="text"
              value={updateLastName}
              onChange={(e) => setUpdateLastName(e.target.value)}
              required
              placeholder="Last Name"
            />
          </div>
          <div className=" flex m-2 flex-row">
            <input
              className="border border-gray-200 rounded-r-lg outline-none focus:ring-1 ring-sky-400 w-full pl-1"
              name="updateEmail"
              type="email"
              value={updateEmail}
              onChange={(e) => setUpdateEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className=" flex m-2 flex-row">
            <input
              className="border border-gray-200 rounded-r-lg outline-none focus:ring-1 ring-sky-400 w-full pl-1"
              name="updatePhoneNumber"
              type="number"
              value={updatePhoneNumber}
              onChange={(e) => setUpdatePhoneNumber(e.target.value)}
              required
              placeholder="Phone Number"
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 rounded bg-sky-400 text-white hover:bg-sky-700 my-4 w-full"
          >
            Update Profile
          </button>
        </div>
      </form>
    </>
  );
};

export default Profile;
