import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

const SingleBookingUser = () => {
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState({});
  const token = localStorage.getItem("DSN:token");

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/booking/single-booking/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBooking(res.data.booking);
        setLat(res.data.booking.lat);
        setLong(res.data.booking.long);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, token, lat, long]);

  return (
    <>
      {loading && <Spinner />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Booking Details | Driver Service Nepal</title>
      </Helmet>
      <div
        data-aos="fade-up"
        className="max-w-screen-xl mt-28 mx-auto px-4 md:px-8 mb-10"
      >
        <div className="items-start justify-between md:flex">
          <div className="max-w-lg">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              User Booking
            </h3>
            <p className="text-gray-600 mt-2">Single Booking Details</p>
          </div>
        </div>
        <Container className="mb-10">
          <p className="text-center text-xl text-sky-700 py-2">
            {booking.firstName} {booking.lastName} Booking Details
          </p>
          <div className=" grid grid-cols-2 grid-flow-row   gap-4">
            <p className="flex gap-1 ">
              <span className="font-bold">Name:</span>
              <span>
                {booking.firstName} {booking.lastName}
              </span>
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold">Email:</span>
              <span>{booking.email}</span>
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Phone Number:</span>
              {booking.phoneNumber}
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Pickup Location:</span>
              {booking.pickUpLocation}
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Drop off Location:</span>
              {booking.dropOffLocation}
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Pickup Time:</span>
              {booking.pickUpTime}
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Time for Ride:</span>
              {booking.timeForRide}
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Ride Shift:</span>
              {booking.rideShift}
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Longitude:</span>
              {booking.long}
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Lattitude:</span>
              {booking.lat}
            </p>
            <p className="flex gap-1 ">
              <span className="font-bold"> Booking Date:</span>
              {moment(booking.bookedAt).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>
          <p className=" ">
            <p className="mt-3 font-bold">Comment:</p>
            <p className="mt-3 text-gray-700 text-sm">{booking.comment}</p>
          </p>
        </Container>
      </div>
    </>
  );
};

export default SingleBookingUser;
