import React from "react";
import DriverDayCharge from "../../Assets/driver-day-charge.webp";
import DriverNightCharge from "../../Assets/driver-night-charge.webp";
import DriverDayAirport from "../../Assets/driver-day-airport.webp";
import DriverNightAirport from "../../Assets/driver-night-airport.webp";

const Gallery = () => {
  const team = [
    {
      avatar: DriverDayCharge,
    },
    {
      avatar: DriverDayAirport,
    },
    {
      avatar: DriverNightCharge,
    },
    {
      avatar: DriverNightAirport,
    },
  ];
  return (
    <>
      {" "}
      <noscript>
        Hire, Kathmandu, valley, Driver, service, Ride Nepal, Driver Service
        Nepal, Driver Service, Driver Service in Kathmandu, Driver Service in
        Nepal, Driver Service in Kathmandu Valley, fulltime, part TimeRanges,
        airport pickup, affordable Price, cheap
      </noscript>
      <section data-aos="fade-up" className="py-14 mb-3">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <div className="max-w-xl mx-auto sm:text-center">
            <h3 className="text-sky-800 text-3xl font-semibold sm:text-4xl">
              Gallery
            </h3>
            <p className="text-gray-600 mt-3">Here are some photos of us.</p>
          </div>
          <div className="mt-12">
            <ul className="grid gap-8 sm:grid-cols-2 md:grid-cols-2">
              {team.map((item, idx) => (
                <li key={idx}>
                  <div className="w-full h-60 sm:h-52 md:h-56">
                    <img
                      src={item.avatar}
                      className="w-full h-full object-cover object-center shadow-md rounded-xl"
                      alt={`Driver Service Nepal ${item.avatar}`}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
