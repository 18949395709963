import React, { useState } from "react";
import Spinner from "../Spinner/Spinner";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../baseUrl";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${baseUrl}/auth/forgot-password`,
        {
          email,
          password,
          confirmPassword,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          navigate("/login");
        } else {
          toast.error(res.data.message);
          navigate("/forgot-password");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
    setLoading(false);
  };
  return (
    <>
      {loading && <Spinner />} <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Password | Driver Service Nepal</title>
      </Helmet>
      <form
        data-aos="fade-up"
        onSubmit={handleSubmit}
        className="mb-5 mt-28 justify-center items-center w-full shadow rounded-lg bg-white px-6 flex flex-col md:w-1/2 lg:w-1/3 m-auto"
      >
        <h2 className="text-2xl my-4">Forgot Password</h2>
        <div className="w-full p-2 justify-start flex flex-col">
          <div className="m-2 flex flex-row">
            <input
              className="border border-gray-200 rounded-r-lg outline-none focus:ring-1 ring-sky-400 w-full pl-1"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className=" flex m-2 flex-row">
            <input
              className="border border-gray-200 rounded-r-lg outline-none focus:ring-1 ring-sky-400 w-full pl-1"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
            />
          </div>
          <div className=" flex m-2 flex-row">
            <input
              className="border border-gray-200 rounded-r-lg outline-none focus:ring-1 ring-sky-400 w-full pl-1"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Confirm Password"
            />
          </div>

          <button
            type="submit"
            className="px-4 py-2 rounded bg-sky-400 text-white hover:bg-sky-700 my-4 w-full"
          >
            Submit
          </button>
          <Link to="/login" className="underline">
            Go to login.
          </Link>
        </div>
      </form>
    </>
  );
};

export default ForgotPassword;
