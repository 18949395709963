import React from "react";
import ChooseUs from "../../Assets/choose-us.webp";
import Experience from "../../Assets/experience.webp";
import WhoWeAre from "../../Assets/who-are-we.webp";

const AboutUs = () => {
  const team = [
    {
      avatar: WhoWeAre,

      title: "What is Driver Service Nepal?",
      desc: "We are a business that specializes in assisting clients in using their vechiles when necessary in conjunction with our drivers.Our professional drivers are among the finest in the business and are well-versed on the roads they use. They can assist you in any condition or time because they are experienced with all the city's twists and turns. This sets us apart from the competition. Driver Service Nepal has began operations.  Since then, Facebook marketing, word-of-mouth, and recommendations from satisfied customers have been the major ways we have been able to grow (slowly but definitely) our clientele.  We have served a few firms, socialites, and celebrities as chauffeurs.",
    },
    {
      avatar: Experience,
      title: "Experience, Certification & Safety",
      desc: "Driver Assistance All of our drivers have professional licenses issued by the Government of Nepal, and Nepal is a reputable firm. Our drivers have a great deal of driving expertise. depending on the roads in Kathmandu and around Nepal, a driver's skill is graded depending on their experience and license. At any hour of the day or night, including at the stroke of midnight, we offer services to our clients. Simply phone us or have a driver booked for you. Our top priority is safety, and we go to considerable efforts to protect both you and our personnel. Our drivers can navigate any route and any scenario because they are highly familiar with the routes. They have extensive driving experience and are well-trained and qualified.",
    },
    {
      avatar: ChooseUs,

      title: "Why Choose Driver Service Nepal?",
      desc: "It is such a pain to drive. Especially here in Nepal where there is year-round traffic, poorly maintained roads, and less than obedient drivers. In order to meet the enormous need for seasoned and dependable drivers that can transport you to your preferred location in the comfort of your own vehicle, DRIVER SERVICE NEPAL was founded. You could need a reliable driver to take you home after a late-night party, on a weekend excursion, or for a shopping trip. By sending qualified, trustworthy, and safe drivers to your door at a convenient rate, our services specifically address these demands.",
    },
  ];
  return (
    <>
      {" "}
      <noscript>
        Hire, Kathmandu, valley, Driver, service, Ride Nepal, Driver Service
        Nepal, Driver Service, Driver Service in Kathmandu, Driver Service in
        Nepal, Driver Service in Kathmandu Valley, fulltime, part TimeRanges,
        airport pickup, affordable Price, cheap
      </noscript>
      <section data-aos="fade-up" className="mt-28 ">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <div className="max-w-xl">
            <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
              Wanna Know About Driver Service Nepal?
            </h3>
            <p className="text-gray-600 mt-3">
              About Driver Service Nepal and its key features.
            </p>
          </div>
          <div className="mt-12">
            <ul className="grid gap-8">
              {team.map((item, idx) => (
                <div key={idx} className="py-2 bg-white">
                  <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
                    <div className="md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                      <div className="md:5/12 lg:w-5/12">
                        <img
                          src={item.avatar}
                          alt={`Driver Service Nepal ${item.title}`}
                          className="w-auto h-auto object-cover object-center shadow-md rounded-xl"
                        />
                      </div>
                      <div className="md:7/12 lg:w-6/12">
                        <h2 className="text-2xl text-gray-900 font-bold md:text-4xl">
                          {item.title}
                        </h2>
                        <p className="mt-6 text-gray-600">{item.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
