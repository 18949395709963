import React from "react";
import ContactForm from "../../Components/Contact/ContactForm";
import Map from "../../Components/Contact/Map";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div className="overflow-x-hidden">
     
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="title" content="Hire Driver now | Contact Driver Service Nepal" />
        <meta
          name="description"
          content="You can hire drivers for part-time, Full-time and on hourly basis, Driver hiring service is available Within Kathmandu valley only ." />
        <title>Contact | Driver Service Nepal</title>
      </Helmet>
      <ContactForm />
      <Map />
    </div>
  );
};

export default Contact;
